import React, { useEffect, useState } from "react";
import MeetingCard from "./MeetingCard";
const MeetingList = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [meetings, setMeetings] = useState([]);
  const [sortOption, setSortOption] = useState("meetingDateTime");

  useEffect(() => {
    fetchMeetings();
  }, [sortOption]);

  const fetchMeetings = () => {
    fetch(`${apiUrl}/get-meetings`)
      .then((response) => response.json())
      .then((data) => {
        // Sort meetings based on the selected option
        const sortedMeetings = sortMeetings(data, sortOption);
        setMeetings(sortedMeetings);
      })
      .catch((error) => console.error("Error fetching meetings:"));
  };

  const sortMeetings = (meetingsData, option) => {
    // Sort meetings based on the selected option
    return meetingsData.sort((a, b) => {
      const dateA =
        option === "returnDateTime" ? a.returnDateTime : a.meetingDateTime;
      const dateB =
        option === "returnDateTime" ? b.returnDateTime : b.meetingDateTime;

      // Handle null values by placing them at the end
      if (dateA === null && dateB !== null) {
        return 1;
      } else if (dateB === null && dateA !== null) {
        return -1;
      }

      return new Date(dateA) - new Date(dateB);
    });
  };

  const handleUpdate = () => {
    fetchMeetings();
  };

  const handleSortChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handleDelete = async (meetingId) => {
    try {
      // Send a DELETE request to your backend endpoint
      const response = await fetch(`${apiUrl}/delete-meeting/${meetingId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        // File deleted successfully, update the state
        setMeetings((prevMeetings) =>
          prevMeetings.filter((meeting) => meeting._id !== meetingId)
        );
      } else {
        // Handle error
        console.error("Failed to delete meeting");
      }
    } catch (error) {
      console.error("Error deleting meeting:");
    }
  };

  const handleEditMeeting = () => {
    fetchMeetings();
  };

  return (
    <div>
      <h1>פגישות:</h1>
      <div className="input-meeting">
        <label className="input-sort__label" htmlFor="meetingStatus">
          מין לפי:
        </label>
        <select
          className="input-sort__field"
          id="sortOption"
          value={sortOption}
          onChange={(e) => handleSortChange(e.target.value)}
        >
          <option value="meetingDateTime">תאריך וזמן פגישה</option>
          <option value="returnDateTime">תאריך וזמן חזרה ללקוח</option>
        </select>
      </div>
      <div className="notice-list">
        {meetings.map((meeting) => (
          <MeetingCard
            key={meeting._id}
            meeting={meeting}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            handleEditMeeting={handleEditMeeting}
          />
        ))}
      </div>
    </div>
  );
};
export default MeetingList;
