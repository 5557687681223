import React, { useState, useEffect } from "react";
import AddNotice from "./AddNotice";
import NoticeList from "./NoticeList";
import axios from "axios";

function NoticesContainer() {
  const [notices, setNotices] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedComponent, setSelectedComponent] = useState("noticeList");

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };

  const addNotice = (newNotice) => {
    setNotices([...notices, newNotice]);
  };
  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = async () => {
    try {
      const response = await axios.get(`${apiUrl}/get-notices`);
      setNotices(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching notices:", error);
    }
  };

  const sortNotices = (criteria) => {
    const sorted = [...notices];
    sorted.sort((a, b) => {
      if (criteria === "date") {
        return new Date(a.DateNotice) - new Date(b.DateNotice);
      } else if (criteria === "priority") {
        return b.priority - a.priority;
      } else if (criteria === "creationDate") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });
    setNotices(sorted);
  };

  const handleDelete = async (noticeId) => {
    try {
      await axios.delete(`${apiUrl}/delete-notice/${noticeId}`);
      setNotices(notices.filter((notice) => notice._id !== noticeId));
      alert("הודעה נמחקה בהצלחה!");
    } catch (error) {
      console.error("Error deleting notice:", error);
      alert("שגיאה במחיקת הודעה, נסה שוב.");
    }
  };

  return (
    <div>
      <div className="radio-inputs">
        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === "addNotice"}
            onChange={() => handleComponentChange("addNotice")}
          />
          <span className="name">הוסף הודעה </span>
        </label>

        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === "noticeList"}
            onChange={() => handleComponentChange("noticeList")}
          />
          <span className="name"> רשימת הודעות</span>
        </label>
      </div>
      {selectedComponent === "addNotice" && (
        <AddNotice onAddNotice={addNotice} />
      )}
      {selectedComponent === "noticeList" && (
        <NoticeList
          notices={notices}
          sortNotices={sortNotices}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
}

export default NoticesContainer;
