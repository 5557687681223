import React, { useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import RegisterStudent from "./RegisterStudent";
import StudentList from "./StudentsList";
import "../css/MeetingCss.css";

const StudentManagmenetContainer = () => {
  const [selectedComponent, setSelectedComponent] = useState("studentList");

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };

  return (
    <div>
      <div className="radio-inputs">
        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === "studentList"}
            onChange={() => handleComponentChange("studentList")}
          />
          <span className="name"> תיק סטודנטים</span>
        </label>
        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === "registerStudent"}
            onChange={() => handleComponentChange("registerStudent")}
          />
          <span className="name">רישום סטודנט חדש</span>
        </label>
      </div>

      {/* Display the selected component based on the state */}
      {selectedComponent === "studentList" && <StudentList />}
      {selectedComponent === "registerStudent" && <RegisterStudent />}
    </div>
  );
};

export default StudentManagmenetContainer;
