// CourseCard.js
import React from "react";
import "../css/CourseCardCss.css";

const CourseCard = ({ course, onCourseClick }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <div className="coursecard" onClick={() => onCourseClick(course.category)}>
      <div className="coursecard-img">
        <img src={`${apiUrl}${course.imageFile}`} alt="Course Image" />
      </div>
      <div className="coursecard-info">
        <div className="coursecard-text">
          <p className="text-title">{course.courseName}</p>
        </div>
        <div className="coursecard-icon">
          <svg className="icon" viewBox="0 0 28 25">
            <path d="M13.145 2.13l1.94-1.867 12.178 12-12.178 12-1.94-1.867 8.931-8.8H.737V10.93h21.339z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
