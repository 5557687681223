// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-website {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Use viewport width instead of percentage */
  height: 100vh; /* Use viewport height instead of percentage */
  border: none; /* Remove border */
  margin-top: -30px;
}
`, "",{"version":3,"sources":["webpack://./src/css/ComingSoonPageCss.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY,EAAE,6CAA6C;EAC3D,aAAa,EAAE,8CAA8C;EAC7D,YAAY,EAAE,kBAAkB;EAChC,iBAAiB;AACnB","sourcesContent":[".iframe-website {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw; /* Use viewport width instead of percentage */\n  height: 100vh; /* Use viewport height instead of percentage */\n  border: none; /* Remove border */\n  margin-top: -30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
