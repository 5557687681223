import React, { useState } from "react";
import "../css/NavBarCss.css"; // Import your custom CSS
import { Link, useNavigate } from "react-router-dom"; // Import the Link and useNavigate components
import { useAuth } from "../authentication/AuthContext";
import BenvenutiLTDLogo from "../courseImages/BENVENUTILTDlogo.png";
function NavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { currentUser, userRole, logout } = useAuth(); // Get the current user and logout function
  const navigate = useNavigate(); // Get the navigate function

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout function from useAuth
      navigate("/login"); // Redirect to the login page after logout
    } catch (error) {
      console.error("Logout failed");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="nav-wrapper">
        <div className="mobile-nav-container">
          <div className="grad-bar"></div>
          <nav className="navbar">
            <div className="logo-container">
              <img
                src={BenvenutiLTDLogo}
                alt="BENVENUTI Logo"
                style={{ width: "150px", height: "auto" }}
              />
            </div>
            <div className="menu-toggle-container">
              <div
                className={`menu-toggle ${isMobileMenuOpen ? "is-active" : ""}`}
                onClick={toggleMobileMenu}
              >
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </div>
            </div>

            <ul
              className={`nav no-search ${
                isMobileMenuOpen ? "mobile-nav" : ""
              }`}
            >
              <li className="nav-item">
                <Link to="/">בית</Link>
              </li>
              <li className="nav-item">
                <Link to="/courses"> קורסים</Link>
              </li>

              {/* Display the "Add Course" link only for admin roles */}
              {currentUser && userRole === "admin" && (
                <li className="nav-item">
                  <Link to="/add-course">הוסף קורס</Link>
                </li>
              )}
              {/* Display the "Teacher container" link only for teacher and admin roles */}
              {currentUser &&
                (userRole === "teacher" || userRole === "admin") && (
                  <li className="nav-item">
                    <Link to="/teacher">פאנל מורים</Link>
                  </li>
                )}
              {/* Display the "Create Account" link only for admin role */}
              {currentUser && userRole === "admin" && (
                <li className="nav-item">
                  <Link to="/create-account">יצור חשבון</Link>
                </li>
              )}

              {currentUser &&  userRole === "registerAdmin"  && (
                <li className="nav-item">
                  <Link to="/student-management-registerA">ניהול סטודנטים </Link>
                </li>
              )}
              
              {currentUser && userRole === "admin"  && (
                <li className="nav-item">
                  <Link to="/student-management">ניהול סטודנטים </Link>
                </li>
              )}

             
              {currentUser && userRole === "admin" && (
                <li className="nav-item">
                  <Link to="/meetings"> פגישות </Link>
                </li>
              )}

              {currentUser && userRole === "admin" && (
                <li className="nav-item">
                  <Link to="/notices"> פאנל הודעות </Link>
                </li>
              )}

              {currentUser && userRole === "admin" && (
                <li className="nav-item">
                  <Link to="/dashboard"> דשבורד </Link>
                </li>
              )}

              {currentUser ? (
                <div className="logout-container">
                  <button className="Btn-logout" onClick={handleLogout}>
                    <div className="sign-logout">
                      <svg viewBox="0 0 512 512">
                        <path
                          d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3
                    27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7
                    0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7
                    14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 
                    9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7
                    0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7
                    0 32 14.3 32 32s-14.3 32-32 32z"
                        ></path>
                      </svg>
                    </div>
                    <div className="text-logout">יצאה</div>
                  </button>
                </div>
              ) : (
                <div className="logout-container">
                  <button
                    className="Btn-logout"
                    onClick={() => navigate("/login")}
                  >
                    <div className="sign-login">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                        />
                      </svg>
                    </div>
                    <div className="text-logout">התחבר</div>
                  </button>
                </div>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
