import React, { useState } from 'react';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import AddVideo from './AddVideo';
import AddCourseFiles from './AddCourseFiles';
import TeacherCourseFileList from './TeacherCourseFileList';

import '../css/MeetingCss.css'

const TeacherContainer = () => {
  const [selectedComponent, setSelectedComponent] = useState('addVideo');

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };

  return (
    <div>
      <div className="radio-inputs">
        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === 'addVideo'}
            onChange={() => handleComponentChange('addVideo')}
          />
          <span className="name">הוסף שיעור</span>
        </label>

        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === 'addCourseFile'}
            onChange={() => handleComponentChange('addCourseFile')}
          />
          <span className="name">הוסף קובץ קורס</span>
        </label>

        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === 'courseFileList'}
            onChange={() => handleComponentChange('courseFileList')}
          />
          <span className="name">קבצי קורס</span>
        </label>
      </div>

      {/* Display the selected component based on the state */}
      {selectedComponent === 'addVideo' && <AddVideo />}
      {selectedComponent === 'addCourseFile' && <AddCourseFiles />}
      {selectedComponent === 'courseFileList' && <TeacherCourseFileList />}
    </div>
  );
};

export default TeacherContainer;
