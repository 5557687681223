import React from "react";
import "../css/VideoCardCss.css";
const VideoCard = ({ video }) => {
  return (
    <div>
      <div className="video-card">
        <div className="video-card__view">
          <iframe
            src={`https://iframe.mediadelivery.net/embed/${video.libraryId}/${video.videoId}?autoplay=false&preload=false`}
            loading="lazy"
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              height: "100%",
              width: "100%",
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen={true}
            title={video.title}
          ></iframe>
        </div>
        <div className="video-card__content">
          <h2>{video.title}</h2>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
