import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../css/AddUserAccountCss.css";
import { useAuth } from "../authentication/AuthContext";

function AddUserCreation() {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    role: "",
  });

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { getIdToken } = useAuth();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleCreateUser = async (event) => {
    event.preventDefault();

    if (inputs.email && inputs.password) {
      try {
        const idToken = await getIdToken();

        await axios.post(`${apiUrl}/create-account?idToken=${idToken}`, {
          email: inputs.email,
          password: inputs.password,
          role: inputs.role,
        });

        alert("נוסף בהצלחה");
        setInputs({ email: "", password: "", role: "" });
        fetchUsers();
      } catch (error) {
        alert("שגיאה, אנא נסה שוב.");
        console.error(error);
      }
    } else {
      alert('נא להזין דוא"ל וסיסמה');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/users`, {
        params: { searchTerm },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchUsers();
    }, 200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const deleteUser = async (uid) => {
    try {
      const shouldDelete = window.confirm(
        "האם אתה בטוח שברצונך למחוק משתמש זה?"
      );

      if (shouldDelete) {
        await axios.delete(`${apiUrl}/delete-users/${uid}`);
        setUsers((prevUsers) => prevUsers.filter((user) => user.uid !== uid));
        alert("נמחק בהצלחה");
        fetchUsers();
      } else {
        alert("פעולת המחיקה בוטלה.");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <div>
      <h1>הוסף חשבון משתמש למערכת</h1>
      <div className="App">
        <div className="count-and-download-card">
          <div className="subtitle">בואו נוסיף חשבון משתמש חדש!</div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="email">
              אימייל
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={inputs.email}
              onChange={(event) => handleChange(event)}
              className="input-meeting__field"
            />
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="password">
              סיסמה
            </label>
            <input
              id="password"
              type="password"
              name="password"
              value={inputs.password}
              onChange={handleChange}
              className="input-meeting__field"
            />
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="studyLocation">
              בחר תפקיד
            </label>
            <select
              id="role"
              name="role"
              value={inputs.role}
              onChange={handleChange}
              className="input-meeting__field"
            >
              <option value="" disabled selected>
                בחר תפקיד
              </option>
              <option value="MedStudent">studente di medicina</option>
              <option value="EngStudent">studente di ingegneria</option>
              <option value="teacher">Insegnante</option>
            </select>
          </div>
          <button type="text" className="submit" onClick={handleCreateUser}>
            הוסף
          </button>
        </div>
      </div>
      <div>
        <h1>חשבונות משתמשים</h1>
        <div className="custom-search-group">
          <svg
            className="custom-search-icon"
            aria-hidden="true"
            viewBox="0 0 24 24"
          >
            <g>
              <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
            </g>
          </svg>
          <input
            placeholder="חפש אימייל"
            type="search"
            className="custom-search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <Table className="table-container">
          <Thead>
            <Tr>
              <Th className="right-align">אימייל</Th>
              <Th className="right-align">זמן יצירה</Th>
              <Th className="right-align">זמן כניסה אחרון</Th>
              <Th className="right-align">תפקיד</Th>
              <Th className="right-align">מחק</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user.uid}>
                <Td>{user.email}</Td>
                <Td>{user.creationTime}</Td>
                <Td>{user.lastSignInTime}</Td>
                <Td>{user.role}</Td>
                <Td>
                  <button
                    class="bin-button"
                    onClick={() => deleteUser(user.uid)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 39 7"
                      class="bin-top"
                    >
                      <line
                        stroke-width="4"
                        stroke="white"
                        y2="5"
                        x2="39"
                        y1="5"
                      ></line>
                      <line
                        stroke-width="3"
                        stroke="white"
                        y2="1.5"
                        x2="26.0357"
                        y1="1.5"
                        x1="12"
                      ></line>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 33 39"
                      class="bin-bottom"
                    >
                      <mask fill="white" id="path-1-inside-1_8_19">
                        <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
                      </mask>
                      <path
                        mask="url(#path-1-inside-1_8_19)"
                        fill="white"
                        d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                      ></path>
                      <path
                        stroke-width="4"
                        stroke="white"
                        d="M12 6L12 29"
                      ></path>
                      <path stroke-width="4" stroke="white" d="M21 6V29"></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 89 80"
                      class="garbage"
                    >
                      <path
                        fill="white"
                        d="M20.5 10.5L37.5 15.5L42.5 11.5L51.5 12.5L68.75 0L72 11.5L79.5 12.5H88.5L87 22L68.75 31.5L75.5066 25L86 26L87 35.5L77.5 48L70.5 49.5L80 50L77.5 71.5L63.5 58.5L53.5 68.5L65.5 70.5L45.5 73L35.5 79.5L28 67L16 63L12 51.5L0 48L16 25L22.5 17L20.5 10.5Z"
                      ></path>
                    </svg>
                  </button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
}

export default AddUserCreation;
