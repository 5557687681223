import React, { useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import AddMeeting from "./AddMeeting";
import MeetingList from "./MeetingList";
import CalendarMeeting from "./CalendarMeeting";
import "../css/MeetingCss.css";

const Meetings = () => {
  const [selectedComponent, setSelectedComponent] = useState("calendar");

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };

  return (
    <div>
      <div className="radio-inputs">
        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === "calendar"}
            onChange={() => handleComponentChange("calendar")}
          />
          <span className="name">הלוז החודשי</span>
        </label>

        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === "addMeeting"}
            onChange={() => handleComponentChange("addMeeting")}
          />
          <span className="name">הוסף פגישה</span>
        </label>

        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === "meetingList"}
            onChange={() => handleComponentChange("meetingList")}
          />
          <span className="name">רשימת פגישות</span>
        </label>
      </div>

      {selectedComponent === "calendar" && <CalendarMeeting />}
      {selectedComponent === "addMeeting" && <AddMeeting />}
      {selectedComponent === "meetingList" && <MeetingList />}
    </div>
  );
};

export default Meetings;
