import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PrivateRoute from "./authentication/PrivateRoute";
import { AuthProvider } from "./authentication/AuthContext";

import ComingSoonPage from "./components/HomePage";
import NavBar from "./components/NavBar";
import Login from "./components/Login";
import AddCourse from "./components/AddCourse";
import CoursesPage from "./components/CoursesPage";
import VideoList from "./components/VideoList";
import AddUserCreation from "./components/AddUserAccountPanel";
import Footer from "./components/Footer";
import Meetings from "./components/Meetings";
import TeacherContainer from "./components/TeacherContainer";
import StudentManagmenetContainer from "./components/StudentManagementContainer";
import StudentManagmenetContainerRegisterAdmin from "./components/StudentManagementContainerRegisterAdmin";
import NoticesContainer from "./components/NoticesContainer";
import Dashboard from "./components/Dashboard";
function App() {
  return (
    <div>
      <AuthProvider>
        <Router>
          <div className="content-container">
            <NavBar />
            <Routes>
              <Route path="/*" element={<ComingSoonPage />} />
              <Route path="login" element={<Login />} />
              <Route
                path="courses/*"
                element={
                  <PrivateRoute
                    element={<CoursesPage />}
                    roles={["MedStudent", "EngStudent", "teacher", "admin","registerAdmin"]}
                  />
                }
              />
              <Route
                path="add-course/*"
                element={
                  <PrivateRoute
                    element={<AddCourse />}
                    roles={["teacher", "admin"]}
                  />
                }
              />

              <Route
                path="video-lessons/:category/*"
                element={
                  <PrivateRoute
                    element={<VideoList />}
                    roles={["MedStudent", "EngStudent", "teacher", "admin","registerAdmin"]}
                  />
                }
              />
              <Route
                path="teacher/*"
                element={
                  <PrivateRoute
                    element={<TeacherContainer />}
                    roles={["teacher", "admin"]}
                  />
                }
              />
              <Route
                path="create-account/*"
                element={
                  <PrivateRoute
                    element={<AddUserCreation />}
                    roles={["admin"]}
                  />
                }
              />
              <Route
                path="student-management/*"
                element={
                  <PrivateRoute
                    element={<StudentManagmenetContainer />}
                    roles={["admin"]}
                  />
                }
              />

                <Route
                path="student-management-registerA/*"
                element={
                  <PrivateRoute
                    element={<StudentManagmenetContainerRegisterAdmin />}
                    roles={["admin","registerAdmin"]}
                  />
                }
              />

              <Route
                path="meetings/*"
                element={
                  <PrivateRoute element={<Meetings />} roles={["admin"]} />
                }
              />

              <Route
                path="notices/*"
                element={
                  <PrivateRoute
                    element={<NoticesContainer />}
                    roles={["admin"]}
                  />
                }
              />

              <Route
                path="dashboard/*"
                element={
                  <PrivateRoute element={<Dashboard />} roles={["admin"]} />
                }
              />
            </Routes>
          </div>
          <Footer />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
