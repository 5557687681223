import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/NoticeListCss.css";
import { format } from "date-fns";

function DashboardNoticeList() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [notices, setNotices] = useState([]);

  const setPriorityWord = (notice) => {
    switch (notice.priority) {
      case "1":
        return "נמוך";
      case "2":
        return "בינוני";
      case "3":
        return "גבוהה";
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = async () => {
    try {
      const response = await axios.get(`${apiUrl}/get-notices`);
      setNotices(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching notices:", error);
    }
  };

  const sortNotices = (criteria) => {
    const sorted = [...notices];
    sorted.sort((a, b) => {
      if (criteria === "date") {
        return new Date(a.DateNotice) - new Date(b.DateNotice);
      } else if (criteria === "priority") {
        return b.priority - a.priority;
      } else if (criteria === "creationDate") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });
    setNotices(sorted);
  };

  const handleDelete = async (noticeId) => {
    const shouldDelete = window.confirm("האם אתה בטוח שברצונך למחוק הודעה זו?");
    if (shouldDelete) {
      try {
        await axios.delete(`${apiUrl}/delete-notice/${noticeId}`);
        setNotices(notices.filter((notice) => notice._id !== noticeId));
        alert("הודעה נמחקה בהצלחה!");
      } catch (error) {
        console.error("Error deleting notice:", error);
        alert("שגיאה במחיקת הודעה, נסה שוב.");
      }
    }
  };

  return (
    <div>
      <h3 className="dashboard-notice-list-h3">רשימת הודעות</h3>

      <div className="input-meeting">
        <label
          className="input-sort__label"
          htmlFor="meetingStatus"
          style={{ color: "#1b2850", marginTop: "5px", marginRight: "25px" }}
        >
          מין לפי:
        </label>
        <select
          className="input-sort__field"
          id="sortOption"
          onChange={(e) => sortNotices(e.target.value)}
          style={{ marginBottom: "8px", marginRight: "25px" }}
        >
          <option value="creationDate">מיון לפי תאריך יצור הודעה</option>
          <option value="date">מיון לפי תאריך מועד אחרון </option>
          <option value="priority">מיון לפי עדיפות</option>
        </select>
      </div>

      <div className="notice-list-dashboard">
        {notices.map((notice) => (
          <div
            className="notice-card"
            style={{ width: "90%", borderRadius: "8px" }}
            key={notice._id}
          >
            <h3>{notice.title}</h3>
            <p>{notice.description}</p>
            <div className="notice-details">
              <span className="date">
                תאריך: {format(new Date(notice.DateNotice), "dd-MM-yyyy")}
              </span>
              <span className="priority">
                עדיפות: {setPriorityWord(notice)}
              </span>
            </div>
            <div className="notice-details">
              <span className="date">
                תאריך הוספה: {format(new Date(notice.createdAt), "dd-MM-yyyy")}
              </span>

              <button
                class="btn-delete-notice"
                onClick={() => handleDelete(notice._id)}
              >
                <svg
                  viewBox="0 0 15 17.5"
                  height="17.5"
                  width="15"
                  xmlns="http://www.w3.org/2000/svg"
                  class="btn-delete-notice"
                >
                  <path
                    transform="translate(-2.5 -1.25)"
                    d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                    id="Fill"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashboardNoticeList;
