// CoursesPage.js
import React, { useEffect, useState, useContext } from "react";
import CourseCard from "./CourseCard";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../authentication/AuthContext";

const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const { userRole } = useContext(AuthContext);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/get-courses`)
      .then((response) => response.json())
      .then((data) => setCourses(data))
      .catch((error) => console.error("Error fetching courses:"));
  }, []);

  const handleCourseClick = (category) => {
    if (userRole === "EngStudent" && category === "biology") {
      alert("אין לך גישה לקורס ביולוגיה.");
    } else {
      navigate(`/video-lessons/${category}`);
    }
  };

  return (
    <div>
      <h1>קורסים</h1>
      <div className="notice-list">
        {courses.map((course) => (
          <CourseCard
            key={course._id}
            course={course}
            onCourseClick={handleCourseClick}
          />
        ))}
      </div>
    </div>
  );
};

export default CoursesPage;
