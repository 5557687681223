import React, { useState } from "react";
import axios from "axios";
import "../css/StudentCardCss.css";

function EditStudentRegisterAdmin({ student, onClose, onEdit }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    firstName: student.firstName,
    lastName: student.lastName,
    dadName: student.dadName,
    momName: student.momName,
    phoneNumber: student.phoneNumber,
    email: student.email,
    dadPhoneNumber: student.dadPhoneNumber,
    momPhoneNumber: student.momPhoneNumber,
    city: student.city,
    studyLocation: student.studyLocation,
    studySubject: student.studySubject,
    studentId: student.studentId,
    userName: student.userName,
    password: student.password,
  });

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Gather form data
      const {
        firstName,
        lastName,
        dadName,
        momName,
        phoneNumber,
        email,
        dadPhoneNumber,
        momPhoneNumber,
        city,
        studyLocation,
        studySubject,
        studentId,
        userName,
        password,
      } = formData;

      // Check if required fields are filled
      if (!firstName || !lastName || !phoneNumber) {
        alert("Please fill in all required fields.");
        return;
      }
      // Create a payload with the form data
      const payload = {
        firstName,
        lastName,
        dadName,
        momName,
        phoneNumber,
        email,
        dadPhoneNumber,
        momPhoneNumber,
        city,
        studyLocation,
        studySubject,
        studentId,
        userName,
        password,
      };

      // Send the form data to the backend endpoint
      const response = await axios.put(
        `${apiUrl}/update-student/${student._id}`,
        payload
      );
      if (response.status == 200) {
        alert("סטודנט נשמר בהצלחה!");
        handleUpdate();
        handleClose();
      } else {
        if (response.status == 500 || response.status == 404) {
          alert("שגיאה בשמירת סטודנט, נסה שוב.");
        }
      }
    } catch (error) {
      // Handle errors
      console.error("Error saving student:");
    }
  };

  const handleClose = () => {
    // Call the onClose function passed as a prop
    onClose();
  };
  const handleUpdate = () => {
    // Call the onClose function passed as a prop
    onEdit();
  };

  return (
    <div>
      <h1>
        ערוך סטודנט {formData.firstName} {formData.lastName}
      </h1>
      <div className="close-container">
        <button class="close-button" onClick={handleClose}>
          <span class="close-icon"></span>
        </button>
      </div>
      <div className="count-and-download-card">
        <div className="subtitle">
          בואו נערוך סטודנט {formData.firstName} {formData.lastName}!
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="firstName">
            שם פרטי
          </label>
          <input
            id="firstName"
            type="text"
            value={formData.firstName}
            onChange={(e) => handleInputChange(e, "firstName")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="lastName">
            שם משפחה
          </label>
          <input
            id="lastName"
            type="text"
            value={formData.lastName}
            onChange={(e) => handleInputChange(e, "lastName")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="dadName">
            שם אב
          </label>
          <input
            id="dadName"
            type="text"
            value={formData.dadName}
            onChange={(e) => handleInputChange(e, "dadName")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>

        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="momName">
            שם אם
          </label>
          <input
            id="momName"
            type="text"
            value={formData.momName}
            onChange={(e) => handleInputChange(e, "momName")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="phoneNumber">
            מ.ס טלפון
          </label>
          <input
            id="phoneNumber"
            value={formData.phoneNumber}
            type="text"
            onChange={(e) => handleInputChange(e, "phoneNumber")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>

        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="email">
            אימייל
          </label>
          <input
            id="email"
            type="text"
            value={formData.email}
            onChange={(e) => handleInputChange(e, "email")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="dadPhoneNumber">
            מ.ס טלפון אב
          </label>
          <input
            id="dadPhoneNumber"
            type="text"
            value={formData.dadPhoneNumber}
            onChange={(e) => handleInputChange(e, "dadPhoneNumber")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="momPhoneNumber">
            מ.ס טלפון אם
          </label>
          <input
            id="momPhoneNumber"
            type="text"
            value={formData.momPhoneNumber}
            onChange={(e) => handleInputChange(e, "momPhoneNumber")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="city">
            עיר
          </label>
          <input
            id="city"
            type="text"
            value={formData.city}
            onChange={(e) => handleInputChange(e, "city")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="studyLocation">
            בחר מיקום לימודים
          </label>
          <select
            id="studyLocation"
            onChange={(e) => handleInputChange(e, "studyLocation")}
            className="input-meeting__field"
            value={formData.studyLocation}
          >
            <option value="" disabled selected>
              בחר מיקום לימודים
            </option>
            <option value="חיפה">חיפה</option>
            <option value="איטליה">איטליה</option>
          </select>
        </div>

        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="studySubject">
            נושא לימודים
          </label>
          <input
            id="studySubject"
            type="text"
            value={formData.studySubject}
            onChange={(e) => handleInputChange(e, "studySubject")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="studentId">
            ת.ז סטודנט
          </label>
          <input
            id="studentId"
            type="text"
            value={formData.studentId}
            onChange={(e) => handleInputChange(e, "studentId")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="userName">
            שם משתמש
          </label>
          <input
            id="userName"
            type="text"
            value={formData.userName}
            onChange={(e) => handleInputChange(e, "userName")}
            className="input-meeting__field"
          />
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="password">
            סיסמה
          </label>
          <input
            id="password"
            type="text"
            value={formData.password}
            onChange={(e) => handleInputChange(e, "password")}
            className="input-meeting__field"
          />
        </div>

        <button type="text" className="submit" onClick={handleSubmit}>
          שמור
        </button>
      </div>
    </div>
  );
}

export default EditStudentRegisterAdmin;
