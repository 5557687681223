import React, { useState, useRef } from "react";
import axios from "axios";
import "../css/StudentCardCss.css";
function RegisterStudent() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    passportPhoto: null,
    bagrotFile: null,
    scholarshipsFile: null,
    translateFile: null,
    ministryOfEducationFile: null,
    universityWebsiteFile: null,
    languageExamFile: null,
    tolcExamFile: null,
    confirmationRegisteryFile: null,
    universitalyFile: null, //new
    firstName: "",
    lastName: "",
    dadName: "",
    momName: "",
    phoneNumber: "",
    email: "",
    dadPhoneNumber: "",
    momPhoneNumber: "",
    city: "",
    studyLocation: "", // italy/haifa
    studySubject: "",
    studentId: "",
    paymentAmount: "",
    paymentPaid: "",
    userName: "",
    password: "",
  });
  const handleFileChange = (event, fileKey) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      [fileKey]: file,
    }));
  };

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check if any required field is empty
    const requiredFields = [
      "firstName",
      "lastName",
      "dadName",
      "momName",
      "phoneNumber",
      "email",
      "city",
      "studyLocation",
      "studySubject",
      "studentId",
    ];
    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      alert("Please enter all fields");
      return;
    }
    const formDataForBackend = new FormData();

    // Append files to the form data
    Object.keys(formData).forEach((key) => {
      if (formData[key] instanceof File) {
        formDataForBackend.append(key, formData[key]);
      } else {
        formDataForBackend.append(key, formData[key]);
      }
    });

    try {
      // Send the form data to the backend endpoint
      const response = await axios.post(
        `${apiUrl}/register`,
        formDataForBackend
      );

      // Handle the response as needed
      alert("תלמיד נרשם בהצלחה");
      setFormData({
        passportPhoto: null,
        bagrotFile: null,
        scholarshipsFile: null,
        translateFile: null,
        ministryOfEducationFile: null,
        universityWebsiteFile: null,
        languageExamFile: null,
        tolcExamFile: null,
        confirmationRegisteryFile: null,
        universitalyFile: null, //new
        firstName: "",
        lastName: "",
        dadName: "",
        momName: "",
        phoneNumber: "",
        email: "",
        dadPhoneNumber: "",
        momPhoneNumber: "",
        city: "",
        studyLocation: "", // italy/haifa
        studySubject: "",
        studentId: "",
        paymentAmount: "",
        paymentPaid: "",
        userName: "",
        password: "",
      });
      formRef.current.reset();
    } catch (error) {
      // Handle errors
      console.error("Error registering student:");
      alert("שגיאה ברישום תלמיד ");
    }
  };

  return (
    <div>
      <h1>הוסף סטודנט חדש </h1>
      <form ref={formRef}>
        <div className="count-and-download-card">
          <div className="subtitle">בואו נרשום סטודנט חדש!</div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="firstName">
              שם פרטי
            </label>
            <input
              id="firstName"
              type="text"
              onChange={(e) => handleInputChange(e, "firstName")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="lastName">
              שם משפחה
            </label>
            <input
              id="lastName"
              type="text"
              onChange={(e) => handleInputChange(e, "lastName")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="dadName">
              שם אבא
            </label>
            <input
              id="dadName"
              type="text"
              onChange={(e) => handleInputChange(e, "dadName")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="momName">
              שם אמא
            </label>
            <input
              id="momName"
              type="text"
              onChange={(e) => handleInputChange(e, "momName")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="phoneNumber">
              מספר טלפון
            </label>
            <input
              id="phoneNumber"
              type="text"
              onChange={(e) => handleInputChange(e, "phoneNumber")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="email">
              אימייל
            </label>
            <input
              id="email"
              type="text"
              onChange={(e) => handleInputChange(e, "email")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="dadPhoneNumber">
              מספר טלפון אבא
            </label>
            <input
              id="dadPhoneNumber"
              type="text"
              onChange={(e) => handleInputChange(e, "dadPhoneNumber")}
              className="input-meeting__field"
            />
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="momPhoneNumber">
              מספר טלפון אמא
            </label>
            <input
              id="momPhoneNumber"
              type="text"
              onChange={(e) => handleInputChange(e, "momPhoneNumber")}
              className="input-meeting__field"
            />
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="city">
              עיר
            </label>
            <input
              id="city"
              type="text"
              onChange={(e) => handleInputChange(e, "city")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="studyLocation">
              בחר מיקום לימודים
            </label>
            <select
              id="studyLocation"
              onChange={(e) => handleInputChange(e, "studyLocation")}
              className="input-meeting__field"
              defaultValue=""
            >
              <option value="" disabled selected>
                בחר מיקום לימודים
              </option>
              <option value="חיפה">חיפה</option>
              <option value="איטליה">איטליה</option>
            </select>
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="studySubject">
              נושא לימודים
            </label>
            <input
              id="studySubject"
              type="text"
              onChange={(e) => handleInputChange(e, "studySubject")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="studentId">
              ת.ז
            </label>
            <input
              id="studentId"
              type="text"
              onChange={(e) => handleInputChange(e, "studentId")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="paymentAmount">
              סכום לתשלום
            </label>
            <input
              id="paymentAmount"
              type="text"
              onChange={(e) => handleInputChange(e, "paymentAmount")}
              className="input-meeting__field"
            />
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="paymentPaid">
              סכום שולם
            </label>
            <input
              id="paymentPaid"
              type="text"
              onChange={(e) => handleInputChange(e, "paymentPaid")}
              className="input-meeting__field"
            />
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="userName">
              שם משתמש
            </label>
            <input
              id="userName"
              type="text"
              onChange={(e) => handleInputChange(e, "userName")}
              className="input-meeting__field"
            />
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="password">
              סיסמה
            </label>
            <input
              id="password"
              type="text"
              onChange={(e) => handleInputChange(e, "password")}
              className="input-meeting__field"
            />
          </div>

          <div className="file-upload-container">
            <label className="custum-file-upload" htmlFor="passportPhoto">
              <span className="upload-title">העלאת תמונת פספורט</span>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.passportPhoto ? (
                  <span>קובץ שנבחר: {formData.passportPhoto.name}</span>
                ) : (
                  <span>לחץ כדי להעלות תמונת פספורט</span>
                )}
              </div>
              <input
                type="file"
                id="passportPhoto"
                onChange={(e) => handleFileChange(e, "passportPhoto")}
              />
            </label>
          </div>
          <div className="file-upload-container">
            <label className="custum-file-upload" htmlFor="bagrotFile">
              <span className="upload-title">העלאת קובץ בגרות</span>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.bagrotFile ? (
                  <span>קובץ שנבחר: {formData.bagrotFile.name}</span>
                ) : (
                  <span>לחץ כדי להעלות קובץ בגרות</span>
                )}
              </div>
              <input
                type="file"
                id="bagrotFile"
                onChange={(e) => handleFileChange(e, "bagrotFile")}
              />
            </label>
          </div>
          <div className="file-upload-container">
            <label className="custum-file-upload" htmlFor="scholarshipsFile">
              <span className="upload-title">העלאת קובץ מילגה</span>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.scholarshipsFile ? (
                  <span>קובץ שנבחר: {formData.scholarshipsFile.name}</span>
                ) : (
                  <span>לחץ כדי להעלות קובץ מילגה</span>
                )}
              </div>
              <input
                type="file"
                id="scholarshipsFile"
                onChange={(e) => handleFileChange(e, "scholarshipsFile")}
              />
            </label>
          </div>
          <div className="file-upload-container">
            <label className="custum-file-upload" htmlFor="translateFile">
              <span className="upload-title">העלאת קובץ תרגום</span>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.translateFile ? (
                  <span>קובץ שנבחר: {formData.translateFile.name}</span>
                ) : (
                  <span>לחץ כדי להעלות קובץ תרגום</span>
                )}
              </div>
              <input
                type="file"
                id="translateFile"
                onChange={(e) => handleFileChange(e, "translateFile")}
              />
            </label>
          </div>
          <div className="file-upload-container">
            <label
              className="custum-file-upload"
              htmlFor="ministryOfEducationFile"
            >
              <span className="upload-title">העלאת קובץ משרד חינוך</span>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.ministryOfEducationFile ? (
                  <span>
                    קובץ שנבחר: {formData.ministryOfEducationFile.name}
                  </span>
                ) : (
                  <span>לחץ כדי להעלות קובץ משרד חינוך</span>
                )}
              </div>
              <input
                type="file"
                id="ministryOfEducationFile"
                onChange={(e) => handleFileChange(e, "ministryOfEducationFile")}
              />
            </label>
          </div>
          <div className="file-upload-container">
            <label
              className="custum-file-upload"
              htmlFor="universityWebsiteFile"
            >
              <span className="upload-title">העלאת קובץ אתר האוניברסיטה</span>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.universityWebsiteFile ? (
                  <span>קובץ שנבחר: {formData.universityWebsiteFile.name}</span>
                ) : (
                  <span>לחץ כדי להעלות קובץ אתר האוניברסיטה</span>
                )}
              </div>
              <input
                type="file"
                id="universityWebsiteFile"
                onChange={(e) => handleFileChange(e, "universityWebsiteFile")}
              />
            </label>
          </div>
          <div className="file-upload-container">
            <label className="custum-file-upload" htmlFor="languageExamFile">
              <span className="upload-title">העלאת קובץ מבחן השפה</span>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.languageExamFile ? (
                  <span>קובץ שנבחר: {formData.languageExamFile.name}</span>
                ) : (
                  <span>לחץ כדי להעלות קובץ מבחן השפה</span>
                )}
              </div>
              <input
                type="file"
                id="languageExamFile"
                onChange={(e) => handleFileChange(e, "languageExamFile")}
              />
            </label>
          </div>
          <div className="file-upload-container">
            <label className="custum-file-upload" htmlFor="tolcExamFile">
              <span className="upload-title">העלאת קובץ מבחן tolc</span>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.tolcExamFile ? (
                  <span>Selected file: {formData.tolcExamFile.name}</span>
                ) : (
                  <span>לחץ כדי להעלות קובץ מבחן tolc</span>
                )}
              </div>
              <input
                type="file"
                id="tolcExamFile"
                onChange={(e) => handleFileChange(e, "tolcExamFile")}
              />
            </label>
          </div>
          <div className="file-upload-container">
            <label
              className="custum-file-upload"
              htmlFor="confirmationRegisteryFile"
            >
              <span className="upload-title">העלאת קובץ אישור הרשמה</span>

              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.confirmationRegisteryFile ? (
                  <span>
                    קובץ שנבחר: {formData.confirmationRegisteryFile.name}
                  </span>
                ) : (
                  <span>לחץ כדי להעלות קובץ אישור הרשמה</span>
                )}
              </div>
              <input
                type="file"
                id="confirmationRegisteryFile"
                onChange={(e) =>
                  handleFileChange(e, "confirmationRegisteryFile")
                }
              />
            </label>
          </div>

          <div className="file-upload-container">
            <label className="custum-file-upload" htmlFor="universitalyFile">
              <span className="upload-title">העלאת קובץ universitaly</span>

              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill=""
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill=""
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="text">
                {formData.universitalyFile ? (
                  <span>קובץ שנבחר: {formData.universitalyFile.name}</span>
                ) : (
                  <span>לחץ כדי להעלות קובץ אישור הרשמה</span>
                )}
              </div>
              <input
                type="file"
                id="universitalyFile"
                onChange={(e) => handleFileChange(e, "universitalyFile")}
              />
            </label>
          </div>

          <button type="text" className="submit" onClick={handleSubmit}>
            הוסף
          </button>
        </div>
      </form>
    </div>
  );
}

export default RegisterStudent;
