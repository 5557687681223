import React, { useState } from "react";
import "../css/StudentFilesCardCss.css";
import axios from "axios";
import { useAuth } from "../authentication/AuthContext";

const EditableField = ({ label, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    onSave(editedValue);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    // Reset the edited value to the original value when canceling
    setEditedValue(value);
  };

  return (
    <div>
      <div class="navigation-card">
        <p>
          <strong>{label}: </strong>
          {isEditing ? (
            <div className="input-meeting">
              <input
                type="text"
                value={editedValue}
                onChange={(e) => setEditedValue(e.target.value)}
                className="input-meeting__field"
              />
            </div>
          ) : (
            <span> {value} ₪</span>
          )}
          {isEditing ? (
            <>
              <button className="button-payment" onClick={handleSaveClick}>
                שמור
              </button>
              <button
                className="button-payment"
                style={{ background: "#ED0A26" }}
                onClick={handleCancelClick}
              >
                ביטול
              </button>
            </>
          ) : (
            <button
              class="icon-btn add-btn"
              style={{ marginRight: "10px" }}
              onClick={handleEditClick}
            >
              <div class="add-icon"></div>
              <div class="add-btn-txt"> ערוך</div>
            </button>
          )}
        </p>
      </div>
    </div>
  );
};

const StudentFilesCard = ({ student, onDelete, onClose, onUpdate, onEdit }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getIdToken } = useAuth();

  const downloadFile = async (filePath) => {
    try {
      const encodedFilePath = encodeURIComponent(filePath);
      const idToken = await getIdToken();

      // Trigger the file download using window.location.href
      window.location.href = `${apiUrl}/download-sensitive-file/${encodedFilePath}?idToken=${idToken}`;
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("file not found");
    }
  };

  const handlePaymentPaidEdit = async (editedValue) => {
    // Perform the logic to update the paymentPaid field in the backend
    try {
      const response = await axios.put(
        `${apiUrl}/update-paymentPaid/${student._id}`,
        {
          paymentPaid: editedValue,
        }
      );

      if (response.status === 200) {
        onUpdate();
        alert("סכום שולם עודכן בהצלחה");
      }
    } catch (error) {
      console.error("Error updating paymentPaid:", error);
      alert("שגיאה בעדכון סכום התשלום");
    }
  };

  const [fileToUpload, setFileToUpload] = useState({
    fieldName: "",
    file: null,
  });

  const handleFileChange = async (fieldName, e) => {
    const file = e.target.files[0];
    await setFileToUpload({ fieldName, file });
  };

  const handleFileDelete = async (fieldName) => {
    try {
      const shouldDelete = window.confirm(
        `Are you sure you want to delete the ${fieldName} file?`
      );

      if (shouldDelete) {
        const response = await axios.delete(
          `${apiUrl}/delete-student-file/${student._id}/${fieldName}`
        );

        if (response.status === 200) {
          // Handle successful file delete (e.g., update UI)
          console.log(`File deleted successfully for ${fieldName}`);
          onUpdate();
        }
      }
    } catch (error) {
      console.error("Error handling file delete:", error);
    }
  };

  const handleFileUpload = async (e) => {
    try {
      e.preventDefault();

      if (!fileToUpload.file) {
        console.error("No file selected for upload");
        return;
      }
      const formData = new FormData();
      formData.append(fileToUpload.fieldName, fileToUpload.file);
      formData.append("firstName", student.firstName);
      formData.append("lastName", student.lastName);
      const response = await axios.put(
        `${apiUrl}/update-student/${student._id}`,
        formData
      );
      if (response.status === 200) {
        // Clear the fileToUpload state
        setFileToUpload({ fieldName: "", file: null });
        // Clear the file input after successful upload
        document.getElementById("file-input").value = "";
        onUpdate();
        alert("קובץ הועלה בהצלחה");
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
    }
  };

  // Helper function for rendering download/upload buttons
  function renderDownloadButton(filePath, label, fieldName) {
    // Check if filePath is a truthy string
    const hasFile =
      filePath !== "null" &&
      filePath !== "undefined" &&
      typeof filePath === "string" &&
      filePath.trim() !== "";

    return (
      <div className="button-container">
        {hasFile ? (
          <div>
            <div className="course-file-card-students">
              <div className="balance-wrapper">
                <span className="balance-heading2">{label}</span>
              </div>
              <button
                className="DownloadButton-Student-files"
                onClick={() => downloadFile(filePath)}
              >
                <svg
                  className="DownloadSvgIcon-Student-files"
                  viewBox="0 0 384 512"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 
                    370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8
                     0 45.3l160 160z"
                  ></path>
                </svg>
                <span className="DownloadIcon2-Student-files"></span>
                <span className="DownloadTooltip-Student-files">הורד</span>
              </button>
            </div>
            <button
              className="delete-button"
              onClick={() => handleFileDelete(fieldName)}
            >
              מחק{" "}
            </button>
          </div>
        ) : (
          <div>
            <form class="form">
              <label for="file-input" class="drop-container">
                <span class="notify">חסר {label}</span>

                <span class="drop-title">בחר קובץ להעלות</span>
                <input
                  type="file"
                  accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                  onChange={(e) => handleFileChange(fieldName, e)}
                  required=""
                  id="file-input"
                />
                <button
                  className="upload-button"
                  onClick={handleFileUpload}
                >{`העלאת קובץ`}</button>
              </label>
            </form>
          </div>
        )}
      </div>
    );
  }
  const handleDelete = async () => {
    try {
      const shouldDelete = window.confirm(
        "האם אתה בטוח שברצונך למחוק סטודנט זה?"
      );
      if (shouldDelete) {
        const response = await axios.delete(
          `${apiUrl}/delete-student/${student._id}`
        );
        if (response.status === 200) {
          // Notify the parent component to update the student list
          onDelete(student._id);
          alert("נמחק בהצלחה");
        }
      } else {
        alert("פעולת המחיקה בוטלה.");
      }
    } catch (error) {
      console.error("Error deleting student:", error);
    }
  };

  return (
    <div className="student-file-list">
      <button class="close-button" onClick={onClose}>
        <span class="close-icon"></span>
      </button>
      <div className="student-card-text">
        <h2 className="h2-name-title">{`${student.firstName} ${student.lastName}`}</h2>
        <div class="navigation-card">
          <p>
            <strong>ת.ז:</strong> {student.studentId}
          </p>
          <p>
            <strong>מספר טלפון:</strong> {student.phoneNumber}
          </p>
          <p>
            <strong>אימייל:</strong> {student.email}
          </p>
          <p>
            <strong>עיר:</strong> {student.city}
          </p>
          <p>
            <strong>נושא לימודים:</strong> {student.studySubject}
          </p>
          <p>
            <strong>מיקום לימודים:</strong> {student.studyLocation}
          </p>
          <p>
            <strong>שם אב:</strong> {student.dadName}
          </p>
          <p>
            <strong>שם אם:</strong> {student.momName}
          </p>
          <p>
            <strong>מספר טלפון אבא:</strong> {student.dadPhoneNumber}
          </p>
          <p>
            <strong>מספר טלפון אמא:</strong> {student.momPhoneNumber}
          </p>
          <p>
            <strong>סכום לתשלום:</strong> {student.paymentAmount} ₪
          </p>
          <p>
            <strong>שם משתמש:</strong> {student.userName}
          </p>
          <p>
            <strong>סיסמה:</strong> {student.password}
          </p>
        </div>

        <EditableField
          label="סכום שולם"
          value={student.paymentPaid}
          onSave={handlePaymentPaidEdit}
        />
      </div>
      <h2 style={{ marginTop: "20px" }}>קבצים:</h2>
      <div className="video-list">
        {renderDownloadButton(
          student.passportPhoto,
          "תמונת פספורט",
          "passportPhoto"
        )}
        {renderDownloadButton(student.bagrotFile, "קובץ בגרות", "bagrotFile")}
        {renderDownloadButton(
          student.scholarshipsFile,
          "קובץ מלגה",
          "scholarshipsFile"
        )}
      </div>
      <div className="video-list">
        {renderDownloadButton(
          student.translateFile,
          "קובץ תרגום",
          "translateFile"
        )}
        {renderDownloadButton(
          student.ministryOfEducationFile,
          "קובץ משרד חינוך",
          "ministryOfEducationFile"
        )}
        {renderDownloadButton(
          student.universityWebsiteFile,
          "קובץ אתר אוניברסיטה",
          "universityWebsiteFile"
        )}
      </div>
      <div className="video-list">
        {renderDownloadButton(
          student.languageExamFile,
          "קובץ מבחן שפה",
          "languageExamFile"
        )}
        {renderDownloadButton(
          student.tolcExamFile,
          "קובץ מבחן tolc",
          "tolcExamFile"
        )}
        {renderDownloadButton(
          student.confirmationRegisteryFile,
          "קובץ אישר הרשמה",
          "confirmationRegisteryFile"
        )}
      </div>
      <div className="video-list">
        {renderDownloadButton(
          student.universitalyFile,
          "קובץ universitaly",
          "universitalyFile"
        )}
      </div>

      <div class="navigation-card">
        <button class="bin-button" onClick={handleDelete}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 39 7"
            class="bin-top"
          >
            <line stroke-width="4" stroke="white" y2="5" x2="39" y1="5"></line>
            <line
              stroke-width="3"
              stroke="white"
              y2="1.5"
              x2="26.0357"
              y1="1.5"
              x1="12"
            ></line>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 33 39"
            class="bin-bottom"
          >
            <mask fill="white" id="path-1-inside-1_8_19">
              <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
            </mask>
            <path
              mask="url(#path-1-inside-1_8_19)"
              fill="white"
              d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
            ></path>
            <path stroke-width="4" stroke="white" d="M12 6L12 29"></path>
            <path stroke-width="4" stroke="white" d="M21 6V29"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 89 80"
            class="garbage"
          >
            <path
              fill="white"
              d="M20.5 10.5L37.5 15.5L42.5 11.5L51.5 12.5L68.75 0L72 11.5L79.5 12.5H88.5L87 22L68.75 31.5L75.5066 25L86 26L87 35.5L77.5 48L70.5 49.5L80 50L77.5 71.5L63.5 58.5L53.5 68.5L65.5 70.5L45.5 73L35.5 79.5L28 67L16 63L12 51.5L0 48L16 25L22.5 17L20.5 10.5Z"
            ></path>
          </svg>
        </button>
        <button class="icon-btn add-btn" onClick={() => onEdit(student)}>
          <div class="add-icon"></div>
          <div class="add-btn-txt"> ערוך</div>
        </button>
      </div>
    </div>
  );
};

export default StudentFilesCard;
