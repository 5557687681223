import React, { useState, useRef } from "react";
import axios from "axios";
import "../css/StudentCardCss.css";

function AddMeeting() {
  const formRef = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    meetingSubject: "",
    meetingDateTime: "",
    meetingSummary: "",
    tasks: "",
    returnDateTime: "",
    meetingStatus: "",
  });

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Gather form data
      const {
        fullName,
        phoneNumber,
        meetingSubject,
        meetingDateTime,
        meetingSummary,
        tasks,
        returnDateTime,
        meetingStatus,
      } = formData;

      // Check if required fields are filled
      if (!fullName || !phoneNumber || !meetingSubject || !meetingDateTime) {
        alert("Please fill in all required fields.");
        return;
      }

      // Create a payload with the form data
      const payload = {
        fullName,
        phoneNumber,
        meetingSubject,
        meetingDateTime: meetingDateTime,
        meetingSummary,
        tasks,
        returnDateTime,
        meetingStatus,
      };

      // Send the form data to the backend endpoint
      const response = await axios.post(`${apiUrl}/add-meeting`, payload);
      alert("פגישה נשמרה בהצלחה!");
      setFormData({
        fullName: "",
        phoneNumber: "",
        meetingSubject: "",
        meetingDateTime: "",
        meetingSummary: "",
        tasks: "",
        returnDateTime: "",
        meetingStatus: "",
      });
      formRef.current.reset();
    } catch (error) {
      // Handle errors
      alert("שגיאה בשמירת פגישה, נסה שוב.");
    }
  };

  return (
    <div>
      <h1>הוסף פגישה חדשה </h1>
      <form ref={formRef}>
        <div className="count-and-download-card">
          <div className="subtitle">בואו נתאם פגישה חדשה!</div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="fullName">
              שם מלא
            </label>
            <input
              id="fullName"
              type="text"
              onChange={(e) => handleInputChange(e, "fullName")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>

          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="phoneNumber">
              מ.ס טלפון
            </label>
            <input
              id="phoneNumber"
              type="text"
              onChange={(e) => handleInputChange(e, "phoneNumber")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="meetingSubject">
              נושא פגישה
            </label>
            <input
              id="meetingSubject"
              type="text"
              onChange={(e) => handleInputChange(e, "meetingSubject")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="meetingDateTime">
              תאריך פגישה
            </label>
            <input
              id="meetingDateTime"
              type="datetime-local"
              onChange={(e) => handleInputChange(e, "meetingDateTime")}
              className="input-meeting__field"
            />
            <p className="input-meeting__description">השדה דרוש</p>
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="meetingSummary">
              סיכום פגישה
            </label>
            <textarea
              style={{ resize: "vertical" }}
              rows="5"
              id="meetingSummary"
              type="text"
              onChange={(e) => handleInputChange(e, "meetingSummary")}
              className="input-meeting__field"
            />
          </div>
          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="tasks">
              משימות
            </label>
            <textarea
              style={{ resize: "vertical" }}
              rows="2"
              id="tasks"
              type="text"
              onChange={(e) => handleInputChange(e, "tasks")}
              className="input-meeting__field"
            />
          </div>

          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="returnDateTime">
              תאריך חזרה
            </label>
            <input
              id="returnDateTime"
              type="datetime-local"
              onChange={(e) => handleInputChange(e, "returnDateTime")}
              className="input-meeting__field"
            />
          </div>

          <div className="input-meeting">
            <label className="input-meeting__label" htmlFor="meetingStatus">
              סטטוס פגישה
            </label>
            <select
              id="meetingStatus"
              onChange={(e) => handleInputChange(e, "meetingStatus")}
              className="input-meeting__field"
              defaultValue=""
            >
              <option value="" disabled>
                בחר סטטוס
              </option>
              <option value="בתהליך">בתהליך</option>
              <option value="סגור">סגור</option>
            </select>
          </div>

          <button type="text" className="submit" onClick={handleSubmit}>
            שמור
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddMeeting;
