import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/he"; // Import Hebrew locale for moment.js
import "../css/CalendarMeetingCss.css";

const CalendarMeeting = () => {
  const [meetings, setMeetings] = useState([]);
  const localizer = momentLocalizer(moment);
  moment.locale("he"); // Set moment.js locale to Hebrew

  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    fetchMeetings();
  }, []);
  const fetchMeetings = () => {
    fetch(`${apiUrl}/get-meetings`)
      .then((response) => response.json())
      .then((data) => {
        setMeetings(data);
      })
      .catch((error) => console.error("Error fetching meetings:", error));
  };

  const events = meetings.map((meeting) => ({
    title: meeting.fullName,
    start: new Date(meeting.meetingDateTime),
    end: new Date(meeting.meetingDateTime),
    phoneNumber: meeting.phoneNumber,
  }));

  // Hebrew formats for react-big-calendar
  const formats = {
    //dateFormat: 'DD/MM/YYYY', // Adjust as needed
    dayFormat: "D",
    weekdayFormat: "dd",
    monthHeaderFormat: "MMMM YYYY", // Adjust for the month format in the header
    dayHeaderFormat: "dddd", // Use full day names
    agendaDateFormat: "D MMMM YYYY",
    agendaTimeFormat: "H:mm",
    agendaTimeRangeFormat: "H:mm - H:mm",
  };

  // Hebrew messages for react-big-calendar
  const messages = {
    allDay: "כל היום",
    previous: "אחורה",
    next: "קדימה",
    today: "היום",
    month: "חודש",
    week: "שבוע",
    day: "יום",
    agenda: "סדר יום",
    date: "תאריך",
    time: "זמן",
    event: "אירוע",
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        rtl
        formats={formats} // Apply Hebrew formats
        messages={messages} // Apply Hebrew messages
      />
    </div>
  );
};

export default CalendarMeeting;
