import React, { useEffect, useState } from "react";
import CourseFileCard from "./CourseFileCard";

const CourseFileList = ({ category }) => {
  const [files, setFiles] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const visibleFilesCount = showAll ? files.length : 5;

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/get-course-files?category=${category}`)
      .then((response) => response.json())
      .then((data) => setFiles(data))
      .catch((error) => console.error("Error fetching course files"));
  }, [category]);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  return (
    <div>
      <h1> קבצי קורס</h1>
      {files.length === 0 ? (
        <p style={{ marginRight: "40px" }}>אין קבצים להצגה כרגע.</p>
      ) : (
        <div className="notice-list">
          {files.slice(0, visibleFilesCount).map((file) => (
            <CourseFileCard key={file._id} file={file} />
          ))}
        </div>
      )}
      {files.length > 5 && (
        <div className="loadmore-container">
          <button className="loadmore" onClick={toggleShowAll}>
            {showAll ? "טען פחות" : "טען עוד"}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-repeat"
              viewBox="0 0 16 16"
            >
              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"></path>
              <path
                fillRule="evenodd"
                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
              ></path>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default CourseFileList;
