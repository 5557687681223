import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./firebase";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const apiUrl = process.env.REACT_APP_API_URL;
  //const apiUrl = 'http://localhost:8080';

  const logout = async (email = currentUser?.email) => {
    try {
      if (email) {
        await axios.post(`${apiUrl}/delete-session`, { email });
      }
      await auth.signOut();
    } catch (error) {
      console.error("Logout failed", error);
    }
  };
  
  useEffect(() => {
    const handleActivity = () => {
      setLastActivityTime(Date.now());
    };
  
    const handleTabClose = () => {
      const data = JSON.stringify({ email: currentUser?.email });
      navigator.sendBeacon(`${apiUrl}/delete-session`, data);
    
      // Logout even if the request fails
      logout();
    };
    
  
    // Add event listeners for user activity and tab close
    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("keydown", handleActivity);
    window.addEventListener("beforeunload", handleTabClose);
  
    // Remove event listeners on cleanup
    return () => {
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  
  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);

      if (user) {
        // Get the user's ID token
        const idToken = await user.getIdToken();

        // Decode the token to get user claims
        const decodedToken = decodeToken(idToken);

        // Set the user's role in the state
        setUserRole(decodedToken?.role || null);
      } else {
        // User is not authenticated, perform any necessary logout actions
        logout();
      }
    });

    // Set up the inactivity timer
    const inactivityTimeout = 60 * 60 * 1000; // One hour in milliseconds
    const timer = setInterval(() => {
      if (Date.now() - lastActivityTime > inactivityTimeout) {
        logout();
      }
    }, 1000); // Check every second for inactivity

    return () => {
      unsubscribe();
      clearInterval(timer);
    };
  }, [lastActivityTime]);

  // Helper function to decode the ID token
  const decodeToken = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Error decoding token", error);
      return null;
    }
  };

  const getIdToken = async () => {
    if (!currentUser) {
      throw new Error("User is not authenticated");
    }
    return await currentUser.getIdToken();
  };

  const handleUserActivity = () => {
    setLastActivityTime(Date.now());
  };

  return (
    <AuthContext.Provider
      value={{ currentUser, userRole, logout, getIdToken, handleUserActivity }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth, AuthContext };
/*const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const logout = async () => {
    try {
      const email = currentUser.email;
      await axios.post(`${apiUrl}/delete-session`, { email });

      await auth.signOut();
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);

      if (user) {
        // Get the user's ID token
        const idToken = await user.getIdToken();

        // Decode the token to get user claims
        const decodedToken = decodeToken(idToken);

        // Set the user's role in the state
        setUserRole(decodedToken?.role || null);
      } else {
        // User is not authenticated, perform any necessary logout actions
        logout();
      }
    });

    return () => unsubscribe();
  }, []);

  /* useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (currentUser) {
        // Offer a confirmation message for better user experience
        const confirmationMessage = 'Are you sure you want to leave? You will be logged out.';
        (event || window.event).returnValue = confirmationMessage; // Compatible with older IE
        return confirmationMessage; // Modern browsers
      }
    };
  
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // Log out if tab is hidden for a reasonable time (e.g., 5 seconds) to avoid premature logouts
        setTimeout(() => {
          if (document.visibilityState === 'hidden' && currentUser) {
            logout();
          }
        }, 5000);
      }
    };
  
    const handleUnload = async () => {
      if (currentUser) {
        const email = currentUser.email;
      await axios.post(`${apiUrl}/delete-session`, { email });
        logout();
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('unload', handleUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('unload', handleUnload);
    };
  }, [currentUser]);*/

  /*
  // Helper function to decode the ID token
  const decodeToken = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Error decoding token", error);
      return null;
    }
  };

  const getIdToken = async () => {
    if (!currentUser) {
      throw new Error("User is not authenticated");
    }
    return await currentUser.getIdToken();
  };

  return (
    <AuthContext.Provider value={{ currentUser, userRole, logout, getIdToken }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth, AuthContext };
*/