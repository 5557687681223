import React, { useEffect, useState } from "react";
import VideoCard from "./VideoCard";
import { useParams } from "react-router-dom";
import "../css/VideoListCss.css";
import CourseFileList from "./CourseFileList"; // Import CourseFileList

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const { category } = useParams();
  const [catTitle, setCatTitle] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleVideosCount, setVisibleVideosCount] = useState(3); // Set the initial visible videos count

  const handleLoadMore = () => {
    setVisibleVideosCount((prevCount) => prevCount + 3); // Increase visible videos count by 5
  };

  const CheckCatTitle = (category) => {
    if (category === "italian") {
      setCatTitle("שפה איטלקית");
    } else {
      if (category === "biology") setCatTitle("ביולוגיה");
    }
    if (category === "physics") {
      setCatTitle("פיזיקה");
    }
    if (category === "math") {
      setCatTitle("מתמטיקה");
    }
    if (category === "logic") {
      setCatTitle("לוגיקה");
    }
    if (category === "chemistry") {
      setCatTitle("כימיה");
    }
  };
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch videos based on the category from your backend API
    // Update the API endpoint based on your backend structure
    CheckCatTitle(category);

    fetch(`${apiUrl}/get-videos?category=${category}`)
      .then((response) => response.json())
      .then((data) => setVideos(data))
      .catch((error) => console.error("Error fetching videos:", error));
  }, [category]);

  useEffect(() => {
    if (searchQuery) {
      // Fetch videos based on the search query
      fetch(`${apiUrl}/search-videos?query=${searchQuery}`)
        .then((response) => response.json())
        .then((data) => setVideos(data))
        .catch((error) => console.error("Error searching videos:", error));
    } else {
      // If searchQuery is empty, fetch all videos
      fetch(`${apiUrl}/get-videos?category=${category}`)
        .then((response) => response.json())
        .then((data) => setVideos(data))
        .catch((error) => console.error("Error fetching videos:", error));
    }
  }, [category, searchQuery]);

  return (
    <div>
      <CourseFileList category={category} />{" "}
      {/* Pass category to CourseFileList */}
      <h1>שיעורי {catTitle}</h1>
      <div className="custom-search-group">
        <svg
          className="custom-search-icon"
          aria-hidden="true"
          viewBox="0 0 24 24"
        >
          <g>
            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
          </g>
        </svg>
        <input
          placeholder="חפש שיעור"
          type="search"
          className="custom-search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="video-list">
        {videos.slice(0, visibleVideosCount).map((video) => (
          <VideoCard key={video._id} video={video} />
        ))}
      </div>
      {videos.length > visibleVideosCount && (
        <div className="loadmore-container">
          <button className="loadmore" onClick={handleLoadMore}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-repeat"
              viewBox="0 0 16 16"
            >
              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"></path>
              <path
                fillRule="evenodd"
                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
              ></path>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoList;
