import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Upload,
  isSupported as tusIsSupported,
  canStoreURLs as tusCanStoreURLs,
  enableDebugLog as tusEnableDebugLog,
  defaultOptions as tusDefaultOptions,
} from "tus-js-client";
import CryptoJS from "crypto-js";
import "../css/AddCourseCss.css";
const AddVideo = () => {
  const [inputs, setInputs] = useState({
    title: "",
    category: "biology",
    imageFile: null,
    videoId: "",
    libraryId: "190934",
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isVideoCreated, setIsVideoCreated] = useState(false);

  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    if (isVideoCreated) {
      // Trigger the upload and submission
      handleSubmit();
      setIsVideoCreated(false); // Reset the flag
    }
  }, [isVideoCreated]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setInputs((values) => ({ ...values, imageFile: file }));
  };

  const sha256 = (data) => {
    return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
  };
  const apiUrl = process.env.REACT_APP_API_URL;

  const createVideo = async () => {
    try {
      const response = await axios.post(`${apiUrl}/createVideo`, {
        title: inputs.title,
      });

      const videoId = response.data.guid;
      setInputs((values) => ({ ...values, videoId: response.data.guid }));

      // Set the flag to indicate that video creation is complete
      setIsVideoCreated(true);
      return videoId;
    } catch (error) {
      console.error("Error creating video:", error);
    }
  };

  const handleUpload = async () => {
    const tusEndpoint = "https://video.bunnycdn.com/tusupload";
    const libraryId = "190934"; // Set the default libraryId
    const expirationTime = Math.floor(Date.now() / 1000) + 3600;
    const collectionId = "biology";
    if (inputs.title && inputs.category && inputs.imageFile) {
      try {
        // Wait for createVideo to finish before proceeding
        const videoId = await createVideo();
        setInputs((values) => ({ ...values, videoId: videoId }));

        if (videoId && inputs.imageFile) {
          const metadata = {
            filetype: inputs.imageFile.type,
            title: inputs.imageFile.name,
            collection: collectionId,
          };

          const signature = sha256(
            libraryId + apiKey + expirationTime + videoId
          );

          const upload = new Upload(inputs.imageFile, {
            endpoint: tusEndpoint,
            retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
            headers: {
              AuthorizationSignature: signature,
              AuthorizationExpire: expirationTime,
              VideoId: videoId,
              LibraryId: libraryId,
            },
            metadata: metadata,
            onError: (error) => {
              console.error("Upload failed:", error);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
              const progress = (bytesUploaded / bytesTotal) * 100;
              setUploadProgress(progress);
            },
            onSuccess: () => {
              handleSubmit();
            },
          });

          upload.start();
        } else {
          console.error("Error creating video or missing imageFile");
        }
      } catch (error) {
        console.error("Error during upload:", error);
      }
    } else {
      alert("בבקשה מלא את כל השדות.");
    }
  };

  const handleSubmit = async () => {
    if (!isVideoCreated) {
      alert("ההעלאה מתבצעת. בבקשה המתן.");
      return;
    }
    if (inputs.title && inputs.category && inputs.imageFile && inputs.videoId) {
      try {
        // Add your logic for handling video data submission here
        await axios.post(`${apiUrl}/add-video`, inputs);
        alert("הסרטון נוסף בהצלחה.");
        setInputs({
          title: "",
          category: "",
          imageFile: null,
          videoId: "",
        });
        setUploadProgress(0); // Reset upload progress after submission
        setIsVideoCreated(false); // Reset the flag
      } catch (error) {
        alert("Error adding video, please try again.");
        console.error(error);
      }
    } else {
      alert("בבקשה מלא את כל השדות.");
    }
  };

  return (
    <div>
      <h1>הוסף שיעור חדש!</h1>
      <div className="count-and-download-card">
        <div className="subtitle">בואו נוסיף שיעור וידאו חדש!</div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="title">
            כותרת וידאו
          </label>
          <input
            type="text"
            name="title"
            value={inputs.title}
            onChange={handleChange}
            className="input-meeting__field"
          />
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="category">
            בחר קורס
          </label>
          <select
            id="category"
            name="category"
            value={inputs.category}
            onChange={handleChange}
            className="input-meeting__field"
          >
            <option value="" disabled selected>
              בחר קורס
            </option>
            <option value="biology">ביולוגיה</option>
            <option value="physics">פיזיקה</option>
            <option value="math">מתמטיקה</option>
            <option value="logic">לוגיקה</option>
            <option value="chemistry">כימיה</option>

          </select>
        </div>

        <label className="custum-file-upload" htmlFor="file">
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24">
              <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
              <g
                stroke-linejoin="round"
                stroke-linecap="round"
                id="SVGRepo_tracerCarrier"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill=""
                  d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>{" "}
              </g>
            </svg>
          </div>
          <div className="text">
            {inputs.imageFile ? (
              <span>קובץ שנבחר: {inputs.imageFile.name}</span>
            ) : (
              <span>בחר וידאו</span>
            )}
          </div>
          <input
            type="file"
            id="file"
            name="imageFile"
            onChange={handleFileChange}
          />
        </label>
        <button type="text" className="submit" onClick={handleUpload}>
          הוסף
        </button>
      </div>
      {uploadProgress > 0 && (
        <div>
          <p>התקדמות העלאה: {uploadProgress.toFixed(2)}%</p>
          <progress value={uploadProgress} max={100} />
        </div>
      )}
    </div>
  );
};

export default AddVideo;
