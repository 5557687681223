// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #eee;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.25rem;
  width: 100%;
  font-size: 14px;
  margin: 0 auto; /* Add this line to center horizontally */
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 0;
  color: rgba(51, 65, 85, 1);
  transition: all 0.15s ease-in-out;
}

.radio-inputs .radio input:checked + .name {
  background-color: #fff;
  font-weight: 600;
}

.meeting-card {
  width: 450px;
  max-width: 650px;
  background: #f8f9fd;
  border-radius: 8px;
  padding: 25px 35px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/css/MeetingCss.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,qBAAqB;EACrB,sBAAsB;EACtB,sBAAsB;EACtB,2CAA2C;EAC3C,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,cAAc,EAAE,yCAAyC;AAC3D;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;EAC1B,iCAAiC;AACnC;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,oCAAoC;EACpC,wCAAwC;EACxC,kBAAkB;AACpB","sourcesContent":[".radio-inputs {\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  border-radius: 0.5rem;\n  background-color: #eee;\n  box-sizing: border-box;\n  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);\n  padding: 0.25rem;\n  width: 100%;\n  font-size: 14px;\n  margin: 0 auto; /* Add this line to center horizontally */\n}\n\n.radio-inputs .radio {\n  flex: 1 1 auto;\n  text-align: center;\n}\n\n.radio-inputs .radio input {\n  display: none;\n}\n\n.radio-inputs .radio .name {\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n  justify-content: center;\n  border-radius: 0.5rem;\n  border: none;\n  padding: 0.5rem 0;\n  color: rgba(51, 65, 85, 1);\n  transition: all 0.15s ease-in-out;\n}\n\n.radio-inputs .radio input:checked + .name {\n  background-color: #fff;\n  font-weight: 600;\n}\n\n.meeting-card {\n  width: 450px;\n  max-width: 650px;\n  background: #f8f9fd;\n  border-radius: 8px;\n  padding: 25px 35px;\n  border: 5px solid rgb(255, 255, 255);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
