import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';



const PrivateRoute = ({ element, roles }) => {
    const { currentUser, userRole } = useContext(AuthContext);
  
    // Check if the user is not logged in
    if (!currentUser) {
      // Redirect to login page
      return <Navigate to="/login" />;
    }
  
    // Check if the user has the required role
    const hasRequiredRole = roles ? roles.includes(userRole) : false;
  
    
    // Return the appropriate route or redirect
    return hasRequiredRole ? element : <Navigate to="/" />;
  };
  
  export default PrivateRoute;