// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modify the background color of events */
.rbc-calendar {
  background-color: #e5e7eb;
  border-radius: 8px;
  padding: 20px;
  height: 850px;
  margin: 20px;
}
.custom-calendar-container {
  box-shadow: 3px 3px 6px #4b5563, -3px -3px 6px #6b7280;
  margin: 20px !important;
  border-radius: 1vw;
  direction: rtl !important ;
}
.rbc-time-view {
}
.rbc-time-header .rbc-overflowing {
}
.rbc-day-slot .rbc-time-column {
}
.rbc-row-bg {
}
.rbc-time-content {
}
.rbc-event {
  background-color: #2957cc;
  direction: rtl !important ;
}

.rbc-time-content {
}
/* Customize the header styles */
.rbc-toolbar-label {
  color: #000000;
}

.rbc-events-container {
  direction: rtl !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-timeslot-group {
  min-height: 120px !important;
}

.rbc-month-row {
  min-height: 140px !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/CalendarMeetingCss.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,YAAY;AACd;AACA;EACE,sDAAsD;EACtD,uBAAuB;EACvB,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;AACA;AACA;AACA;AACA;AACA;AACA;AACA;AACA;AACA;AACA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;AACA;AACA,gCAAgC;AAChC;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":["/* Modify the background color of events */\n.rbc-calendar {\n  background-color: #e5e7eb;\n  border-radius: 8px;\n  padding: 20px;\n  height: 850px;\n  margin: 20px;\n}\n.custom-calendar-container {\n  box-shadow: 3px 3px 6px #4b5563, -3px -3px 6px #6b7280;\n  margin: 20px !important;\n  border-radius: 1vw;\n  direction: rtl !important ;\n}\n.rbc-time-view {\n}\n.rbc-time-header .rbc-overflowing {\n}\n.rbc-day-slot .rbc-time-column {\n}\n.rbc-row-bg {\n}\n.rbc-time-content {\n}\n.rbc-event {\n  background-color: #2957cc;\n  direction: rtl !important ;\n}\n\n.rbc-time-content {\n}\n/* Customize the header styles */\n.rbc-toolbar-label {\n  color: #000000;\n}\n\n.rbc-events-container {\n  direction: rtl !important;\n}\n\n.rbc-event-label {\n  display: none !important;\n}\n\n.rbc-timeslot-group {\n  min-height: 120px !important;\n}\n\n.rbc-month-row {\n  min-height: 140px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
