// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #2556ad;
  color: white;
  text-align: center;
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 12;
}

.social-icons a {
  margin-left: 10px;
}

.social-icons svg {
  width: 30px;
  height: 30px;
  fill: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/css/FooterCss.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,SAAS;EACT,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".footer {\n  background-color: #2556ad;\n  color: white;\n  text-align: center;\n  padding: 20px;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  z-index: 12;\n}\n\n.social-icons a {\n  margin-left: 10px;\n}\n\n.social-icons svg {\n  width: 30px;\n  height: 30px;\n  fill: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
