import React from "react";
import "../css/CourseFileCardCss.css";

const CourseFileCard = ({ file }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const downloadFile = async () => {
    try {
      window.location.href = `${apiUrl}/download-course-file/${file._id}`;
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="course-file-card">
      <div className="balance-wrapper">
        <span className="balance-heading">{file.title}</span>
        <p className="balance">{file.description}</p>
      </div>
      <button className="DownloadButton" onClick={downloadFile}>
        <svg
          className="DownloadSvgIcon"
          viewBox="0 0 384 512"
          height="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 
                    370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8
                     0 45.3l160 160z"
          ></path>
        </svg>
        <span className="DownloadIcon2"></span>
        <span className="DownloadTooltip">הורד</span>
      </button>
    </div>
  );
};

export default CourseFileCard;
