import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/NoticeListCss.css";
import { format } from "date-fns";
import { Container, Row, Col } from 'react-bootstrap';

function NoticeList({ notices, sortNotices, onDelete }) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const setPriorityWord = (notice) => {
    switch (notice.priority) {
      case "1":
        return "נמוך";
      case "2":
        return "בינוני";
      case "3":
        return "גבוהה";
      default:
        return "";
    }
  };

  const handleDelete = async (noticeId) => {
    const shouldDelete = window.confirm("האם אתה בטוח שברצונך למחוק הודעה זו?");
    if (shouldDelete) {
      onDelete(noticeId);
    }
  };

  return (
    <div>
      <h1>רשימת הודעות</h1>
      <div className="input-meeting">
        <label className="input-sort__label" htmlFor="meetingStatus">
          מין לפי:
        </label>
        <select
          className="input-sort__field"
          id="sortOption"
          onChange={(e) => sortNotices(e.target.value)}
        >
          <option value="creationDate">מיון לפי תאריך יצור הודעה</option>
          <option value="date">מיון לפי תאריך מועד אחרון </option>
          <option value="priority">מיון לפי עדיפות</option>
        </select>
      </div>

      <div className="notice-list">
        {notices.map((notice) => (
          <div className="notice-card" key={notice._id}>
            <h3>{notice.title}</h3>
            <p>{notice.description}</p>
            <div className="notice-details">
              <span className="date">
                תאריך: {format(new Date(notice.DateNotice), "dd-MM-yyyy")}
              </span>
              <span className="priority">
                עדיפות: {setPriorityWord(notice)}
              </span>
            </div>
            <div className="notice-details">
              <span className="date">
                תאריך הוספה: {format(new Date(notice.createdAt), "dd-MM-yyyy")}
              </span>

              <button
                class="btn-delete-notice"
                onClick={() => handleDelete(notice._id)}
              >
                <svg
                  viewBox="0 0 15 17.5"
                  height="17.5"
                  width="15"
                  xmlns="http://www.w3.org/2000/svg"
                  class="btn-delete-notice"
                >
                  <path
                    transform="translate(-2.5 -1.25)"
                    d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                    id="Fill"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NoticeList;
