import React from "react";
import "../css/ComingSoonPageCss.css";

const ComingSoonPage = () => {
  return (
    <div>
      <iframe
        className="iframe-website"
        src="https://www.benvenuti.co.il/benvenuti/"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default ComingSoonPage;
