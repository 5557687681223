import React, { useState } from "react";
import axios from "axios";
import "../css/StudentCardCss.css";

function EditMeeting({ meeting, onClose, onUpdate }) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    if (!dateString) {
      return null; // Return null for empty or null date strings
    }
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16).replace("T", " ");
  };

  const [formData, setFormData] = useState({
    fullName: meeting.fullName,
    phoneNumber: meeting.phoneNumber,
    meetingSubject: meeting.meetingSubject,
    meetingDateTime: formatDate(meeting.meetingDateTime),
    meetingSummary: meeting.meetingSummary,
    tasks: meeting.tasks,
    returnDateTime: formatDate(meeting.returnDateTime),
    meetingStatus: meeting.meetingStatus,
  });

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Gather form data
      const {
        fullName,
        phoneNumber,
        meetingSubject,
        meetingDateTime,
        meetingSummary,
        tasks,
        returnDateTime,
        meetingStatus,
      } = formData;

      // Check if required fields are filled
      if (!fullName || !phoneNumber || !meetingSubject || !meetingDateTime) {
        alert("Please fill in all required fields.");
        return;
      }

      // Create a payload with the form data
      const payload = {
        fullName,
        phoneNumber,
        meetingSubject,
        meetingDateTime: meetingDateTime,
        meetingSummary,
        tasks,
        returnDateTime,
        meetingStatus,
      };

      // Send the form data to the backend endpoint
      const response = await axios.put(
        `${apiUrl}/update-meeting/${meeting._id}`,
        payload
      );

      // Handle the response as needed
      alert("פגישה נשמרה בהצלחה!");
      handleUpdate();
      handleClose();
    } catch (error) {
      // Handle errors
      console.error("Error saving meeting:");
      alert("שגיאה בשמירת פגישה, נסה שוב.");
    }
  };

  const handleClose = () => {
    // Call the onClose function passed as a prop
    onClose();
  };
  const handleUpdate = () => {
    // Call the onClose function passed as a prop
    onUpdate();
  };

  return (
    <div>
      <h1>ערוך פגישה</h1>
      <div className="close-container">
        <button class="close-button" onClick={handleClose}>
          <span class="close-icon"></span>
        </button>
      </div>
      <div className="count-and-download-card">
        <div className="subtitle">בואו נסכם את הפגישה!</div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="fullName">
            שם מלא
          </label>
          <input
            id="fullName"
            type="text"
            value={formData.fullName}
            onChange={(e) => handleInputChange(e, "fullName")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>

        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="phoneNumber">
            מ.ס טלפון
          </label>
          <input
            id="phoneNumber"
            value={formData.phoneNumber}
            type="text"
            onChange={(e) => handleInputChange(e, "phoneNumber")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="meetingSubject">
            נושא פגישה
          </label>
          <input
            id="meetingSubject"
            value={formData.meetingSubject}
            type="text"
            onChange={(e) => handleInputChange(e, "meetingSubject")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="meetingDateTime">
            תאריך פגישה
          </label>
          <input
            id="meetingDateTime"
            value={formData.meetingDateTime}
            type="datetime-local"
            onChange={(e) => handleInputChange(e, "meetingDateTime")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="meetingSummary">
            סיכום פגישה
          </label>
          <textarea
            style={{ resize: "vertical" }}
            rows="5"
            id="meetingSummary"
            value={formData.meetingSummary}
            type="text"
            onChange={(e) => handleInputChange(e, "meetingSummary")}
            className="input-meeting__field"
          />
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="tasks">
            משימות
          </label>
          <textarea
            style={{ resize: "vertical" }}
            rows="2"
            id="tasks"
            value={formData.tasks}
            type="text"
            onChange={(e) => handleInputChange(e, "tasks")}
            className="input-meeting__field"
          />
        </div>

        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="returnDateTime">
            תאריך חזרה
          </label>
          <input
            id="returnDateTime"
            value={formData.returnDateTime}
            type="datetime-local"
            onChange={(e) => handleInputChange(e, "returnDateTime")}
            className="input-meeting__field"
          />
        </div>

        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="meetingStatus">
            סטטוס פגישה
          </label>
          <select
            id="meetingStatus"
            value={formData.meetingStatus}
            onChange={(e) => handleInputChange(e, "meetingStatus")}
            className="input-meeting__field"
            defaultValue=""
          >
            <option value="" disabled>
              בחר סטטוס
            </option>
            <option value="בתהליך">בתהליך</option>
            <option value="סגור">סגור</option>
          </select>
        </div>

        <button type="text" className="submit" onClick={handleSubmit}>
          שמור
        </button>
      </div>
    </div>
  );
}

export default EditMeeting;
