import React, { useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import RegisterStudent from "./RegisterStudent";
import StudentListRegisterAdmin from "./StudentListRegisterAdmin";
import "../css/MeetingCss.css";

const StudentManagmenetContainerRegisterAdmin = () => {
  const [selectedComponent, setSelectedComponent] = useState(
    "StudentCardRegisterAdmin"
  );

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };

  return (
    <div>
      <div className="radio-inputs">
        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === "StudentListRegisterAdmin"}
            onChange={() => handleComponentChange("StudentListRegisterAdmin")}
          />
          <span className="name"> תיק סטודנטים</span>
        </label>
        <label className="radio">
          <input
            type="radio"
            name="radio"
            checked={selectedComponent === "registerStudent"}
            onChange={() => handleComponentChange("registerStudent")}
          />
          <span className="name">רישום סטודנט חדש</span>
        </label>
      </div>

      {/* Display the selected component based on the state */}
      {selectedComponent === "StudentCardRegisterAdmin" && (
        <StudentListRegisterAdmin />
      )}
      {selectedComponent === "registerStudent" && <RegisterStudent />}
    </div>
  );
};

export default StudentManagmenetContainerRegisterAdmin;
