// Login.js
import React, { useState } from "react";
import { auth } from "../authentication/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../authentication/AuthContext";
import { sendPasswordResetEmail } from "firebase/auth";

import "../css/LoginCss.css";
import axios from "axios";

const Login = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;
 // const apiUrl = "http://localhost:8080";
  // Check if the user is already authenticated
  if (currentUser) {
    navigate("/courses");
    return null;
  }
  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, forgotPasswordEmail);
      alert("אימייל איפוס סיסמה נשלח. אנא בדוק את תיבת הדואר הנכנס שלך");
      setShowForgotPasswordModal(false);
    } catch (error) {
      alert("שגיאה בשליחת איפוס סיסמה נא לנסות שוב");
    }
  };
  const onLogin = async (e) => {
    e.preventDefault();
    try {
      const checkSessionResponse = await axios.post(`${apiUrl}/check-session`, {
        email: email,
      });
      if (checkSessionResponse.data.error) {
        // User already has an active session
        console.error("User already has an active session");
        alert("למשתמש כבר יש הפעלה פעילה");
        return;
      } else {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        // Signed in
        const user = userCredential.user;
        // Continue with the session creation logic
        const createSessionResponse = await axios.post(
          `${apiUrl}/create-session`,
          { email: email }
        );
        const sessionId = createSessionResponse.data.sessionId;
        alert("כניסה מוצלחת");

        navigate("/courses");
      }
    } catch (error) {
      alert("אימייל או סיסמה לא נכונים");
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
    }
  };

  return (
    <div className="App-login">
      <div className="login-container">
        <div className="login-heading">התחבר</div>
        <form action="" className="login-form">
          <input
            required
            className="login-input"
            type="email"
            name="email"
            id="email"
            placeholder="דואר אלקטרוני"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            required
            className="login-input"
            type="password"
            name="password"
            id="password"
            placeholder="סיסמה"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            onClick={() => setShowForgotPasswordModal(true)}
            className="login-forgot-password"
          >
            <a href="#">שכחת הסיסמא ?</a>
          </span>
          <input
            className="login-submit-button"
            type="submit"
            value="התחבר"
            onClick={onLogin}
          />
        </form>
      </div>
      {showForgotPasswordModal && (
        <div className="login-container">
          <div className="modal-content">
            <h2 className="login-heading">שכחת הסיסמה</h2>
            <p>הכנס את כתובת הדוא"ל שלך ונשלח לך לינק לאיפוס הסיסמה</p>
            <form action="" className="login-form">
              <input
                className="login-input"
                type="email"
                placeholder="דואר אלקטרוני"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              />
            </form>

            <button
              className="login-submit-button"
              onClick={handleResetPassword}
            >
              שלח
            </button>
            <button
              className="login-cancel-button"
              onClick={() => setShowForgotPasswordModal(false)}
            >
              ביטול
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
