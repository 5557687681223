import React, { useState } from "react";
import axios from "axios";
import "../css/StudentCardCss.css";
function AddNotice({ onAddNotice }) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    DateNotice: "",
    priority: "",
  });

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Gather form data
      const { title, description, DateNotice, priority } = formData;

      // Check if required fields are filled
      if (!title || !description) {
        alert("Please fill in all required fields.");
        return;
      }

      // Create a payload with the form data
      const payload = {
        title,
        description,
        DateNotice,
        priority,
      };

      // Send the form data to the backend endpoint
      const response = await axios.post(`${apiUrl}/add-notice`, payload);

      alert("הודעה נשמרה בהצלחה!");
      // Clear the form fields after successful submission
      setFormData({
        title: "",
        description: "",
        DateNotice: "",
        priority: "",
      });
      onAddNotice(response.data.notice);
    } catch (error) {
      // Handle errors
      alert("שגיאה בשמירת הודעה, נסה שוב.");
    }
  };

  return (
    <div>
      <h1>הוסף הודעה חדשה </h1>
      <div className="count-and-download-card">
        <div className="subtitle">בואו נוסיף הודעה חדשה!</div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="title">
            כותרת
          </label>
          <input
            id="title"
            type="text"
            value={formData.title}
            onChange={(e) => handleInputChange(e, "title")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>

        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="description">
            תיאור
          </label>
          <textarea
            style={{ resize: "vertical" }}
            rows="5"
            id="description"
            type="text"
            value={formData.description}
            onChange={(e) => handleInputChange(e, "description")}
            className="input-meeting__field"
          />
          <p className="input-meeting__description">השדה דרוש</p>
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="DateNotice">
            תאריך
          </label>
          <input
            id="DateNotice"
            type="date"
            value={formData.DateNotice}
            onChange={(e) => handleInputChange(e, "DateNotice")}
            className="input-meeting__field"
          />
        </div>
        <div className="input-meeting">
          <label className="input-meeting__label" htmlFor="priority">
            עדיפות
          </label>
          <select
            id="priority"
            onChange={(e) => handleInputChange(e, "priority")}
            className="input-meeting__field"
            defaultValue=""
          >
            <option value="" disabled>
              בחר עדיפות
            </option>
            <option value="1">עדיפות נמוכה </option>
            <option value="2">עדיפות בינונית</option>
            <option value="2">עדיפות גבוה</option>
          </select>
        </div>
        <button type="text" className="submit" onClick={handleSubmit}>
          שמור הודעה
        </button>
      </div>
    </div>
  );
}

export default AddNotice;
