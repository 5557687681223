import React, { useEffect, useState } from "react";
import StudentCardRegisterAdmin from "./StudentCardRegisterAdmin";
import EditStudentRegisterAdmin from "./EditStudentRegisterAdmin";
import { useAuth } from "../authentication/AuthContext";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

const StudentListRegisterAdmin = () => {
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showMissingFilesOnly, setShowMissingFilesOnly] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const { getIdToken } = useAuth();
  const openEditPopup = (student) => {
    setSelectedStudent(student);
    setEditOpen(true);
  };

  const closeEditPopup = () => {
    setSelectedStudent(null);
    setEditOpen(false);
  };
  const handleDisplayAll = (student) => {
    setSelectedStudent(student);
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleDelete = (deletedStudentId) => {
    setStudents((prevStudents) =>
      prevStudents.filter((student) => student._id !== deletedStudentId)
    );
  };

  const handleUpdate = () => {
    fetchStudents();
  };

  const fetchStudents = async () => {
    try {
      const idToken = await getIdToken();
      const response = await axios.get(
        `${apiUrl}/get-students?idToken=${idToken}`
      );
      setStudents(response.data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      fetch(`${apiUrl}/search-students?query=${searchQuery}`)
        .then((response) => response.json())
        .then((data) => setStudents(data))
        .catch((error) => console.error("Error searching students:", error));
    } else {
      fetchStudents();
    }
  }, [searchQuery]);

  const checkFiles = (student) => {
    const filesToCheck = [
      "passportPhoto",
      "bagrotFile",
      "scholarshipsFile",
      "translateFile",
      "ministryOfEducationFile",
      "universityWebsiteFile",
      "languageExamFile",
      "tolcExamFile",
      "confirmationRegisteryFile",
      "universitalyFile",
    ];

    const missingFiles = filesToCheck.filter((file) => {
      return (
        !student[file] ||
        student[file] === "null" ||
        student[file] === "undefined"
      );
    });

    if (missingFiles.length === 0) {
      return "כל המסמכים נמצאים";
    } else {
      return missingFiles
        .map((file) => {
          switch (file) {
            case "passportPhoto":
              return "חסר תמונת פספורט";
            case "bagrotFile":
              return "חסר קובץ בגרות";
            case "scholarshipsFile":
              return "חסר קובץ מלגה";
            case "translateFile":
              return "חסר קובץ תרגום";
            case "ministryOfEducationFile":
              return "חסר קובץ משרד חינוך";
            case "universityWebsiteFile":
              return "חסר קובץ אתר אוניברסיטה";
            case "languageExamFile":
              return "חסר קובץ מבחן השפה";
            case "tolcExamFile":
              return "חסר קובץ מבחן tolc";
            case "confirmationRegisteryFile":
              return "חסר קובץ אישור הרשמה";
            default:
              return file;
          }
        })
        .join(", ");
    }
  };

  const handleClose = (student) => {
    setSelectedStudent((prev) => ({ ...prev, [student.uid]: false }));
  };

  const handleToggleSwitch = () => {
    setShowMissingFilesOnly((prev) => !prev);
  };

  return (
    <div>
      <h1>תיק סטודנטים</h1>
      <div className="custom-search-group">
        <svg
          className="custom-search-icon"
          aria-hidden="true"
          viewBox="0 0 24 24"
        >
          <g>
            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
          </g>
        </svg>

        <input
          placeholder="חפש סטודנט"
          type="search"
          className="custom-search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="toggle-container">
        <div className="checkbox-wrapper-9">
          <input
            type="checkbox"
            id="cb4-9"
            className="tgl tgl-flat"
            checked={showMissingFilesOnly}
            onChange={handleToggleSwitch}
          />
          <label className="tgl-btn" htmlFor="cb4-9"></label>
        </div>
        <label className="toggle-label" htmlFor="cb4-9">
          הצג רק סטודנטים עם קבצים חסרים
        </label>
      </div>

      <Table className="table-container">
        <Thead>
          <Tr>
            <Th className="right-align">שם</Th>
            <Th className="right-align">שם משפחה</Th>
            <Th className="right-align">ת.ז</Th>
            <Th className="right-align">מ.ס טלפון</Th>
            <Th className="right-align">עיר</Th>
            <Th className="right-align">נושא לימודים</Th>
            <Th className="right-align">מיקום לימודים</Th>
            <Th className="right-align"> מסמכים חסרים</Th>
          </Tr>
        </Thead>
        <Tbody>
          {students.map((student) => (
            <React.Fragment key={student.uid}>
              {showMissingFilesOnly &&
                checkFiles(student) !== "כל המסמכים נמצאים" && (
                  <Tr>
                    <Td>{student.firstName}</Td>
                    <Td>{student.lastName}</Td>
                    <Td>{student.studentId}</Td>
                    <Td>{student.phoneNumber}</Td>
                    <Td>{student.city}</Td>
                    <Td>{student.studySubject}</Td>
                    <Td>{student.studyLocation}</Td>
                    <Td
                      className={
                        checkFiles(student) &&
                        checkFiles(student) !== "כל המסמכים נמצאים"
                          ? "red-text"
                          : "green-text"
                      }
                    >
                      {checkFiles(student)}
                    </Td>

                    <Td>
                      <button
                        className="upload-button"
                        onClick={() => handleDisplayAll(student)}
                      >
                        הצג הכל
                      </button>
                    </Td>
                  </Tr>
                )}
              {!showMissingFilesOnly && (
                <Tr>
                  <Td>{student.firstName}</Td>
                  <Td>{student.lastName}</Td>
                  <Td>{student.studentId}</Td>
                  <Td>{student.phoneNumber}</Td>
                  <Td>{student.city}</Td>
                  <Td>{student.studySubject}</Td>
                  <Td>{student.studyLocation}</Td>

                  <Td
                    className={
                      checkFiles(student) &&
                      checkFiles(student) !== "כל המסמכים נמצאים"
                        ? "red-text"
                        : "green-text"
                    }
                  >
                    {checkFiles(student)}
                  </Td>

                  <Td>
                    <button
                      className="upload-button"
                      onClick={() => handleDisplayAll(student)}
                    >
                      הצג הכל
                    </button>
                  </Td>
                </Tr>
              )}
              {selectedStudent === student && (
                <Tr>
                  <Td colSpan="13">
                    <StudentCardRegisterAdmin
                      onUpdate={handleUpdate}
                      onEdit={() => openEditPopup(selectedStudent)}
                      onClose={handleClose}
                      onDelete={handleDelete}
                      student={selectedStudent}
                    />

                    {isEditOpen && (
                      <EditStudentRegisterAdmin
                        student={selectedStudent}
                        onClose={closeEditPopup}
                        onEdit={handleUpdate}
                      />
                    )}
                  </Td>
                </Tr>
              )}
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default StudentListRegisterAdmin;
